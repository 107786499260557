'use strict';

angular.module('enervexSalesappApp')
.config(function ($stateProvider) {
	$stateProvider
	.state('login', {
		url: '/auth/login?redirect&email',
		templateUrl: 'app/profile/login/login.html',
		controller: 'LoginCtrl'
	})
	.state('signup', {
		url: '/signup?redirect&email',
		templateUrl: 'app/profile/signup/signup.html',
		controller: 'SignupCtrl'
	})
	.state('dialog', {
		url: '/auth/dialog/authorize?response_type&redirect_uri&scope&client_id',
		templateUrl: 'app/profile/dialog/dialog.html',
		controller: 'DialogCtrl'
	})
	.state('settings', {
		url: '/settings?activeForm&applicationType',
		templateUrl: 'app/profile/settings/settings.html',
		controller: 'SettingsCtrl',
		authenticate: true
	})
	.state('forgot', {
		url: '/forgot',
		templateUrl: 'app/profile/forgot/forgot.html',
		controller: 'ForgotCtrl'
	})
	.state('reset', {
		url: '/reset/:token',
		templateUrl: 'app/profile/reset/reset.html',
		controller: 'ResetCtrl'
	})
	.state('registration', {
		url: '/registrations/:id',
		templateUrl: 'app/profile/registration/registration.html',
		controller: 'RegistrationCtrl',
		authenticate: false
	})
	.state('terms', {
		url: '/terms',
		templateUrl: 'app/profile/business/terms.html',
		controller: 'TermsCtrl',
		authenticate: false
	})
	.state('privacy', {
		url: '/privacy',
		templateUrl: 'app/profile/business/privacy.html',
		controller: 'PrivacyCtrl',
		authenticate: false
	})
	;
});
